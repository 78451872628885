<template>
  <div class="flex w-full align-center items-center" style="cursor: pointer" @click="handleClick()">
    <template v-bind:key="`assignee-${index}`" v-for="(item, index) in items">
      <v-tooltip right>
        <template v-slot:activator="{ props }">
          <v-avatar v-bind="props" size="40" :color="item.color || '#E6E6E6'" style="margin: 0 2px">
            <img v-if="item.imageUrl" :src="item.imageUrl" />
            <span v-else :class="`black--text ${item.small ? '' : 'text-h5'}`">{{ item.initial }}</span>
          </v-avatar>
        </template>
        <span v-if="!Array.isArray(item.name)">{{ item.name }}</span>
        <ul v-else class="pl-2 mb-0 list-disc text-white">
          <li v-bind:key="`${name}-${index}`" class="mb-0" v-for="(name, index) in item.name">
            {{ name }}
          </li>
        </ul>
      </v-tooltip>
    </template>
  </div>
</template>

<script>
const initial = (name) => {
  const nameArr = name.toString().split(" ");
  if (nameArr.length >= 4) {
    return (
      nameArr[0][0].toString().toUpperCase() +
      nameArr[1][0].toString().toUpperCase()
    );
  } else {
    return name[0].toString().toUpperCase();
  }
};

const getList = (items) => {
  if (items.length > 2) {
    const clonedItems = Array.from(items);
    const firstItem = clonedItems.shift();

    return [
      { ...firstItem, initial: initial(firstItem.name) },
      {
        imageUrl: "",
        name: clonedItems.map((value) => value.name),
        initial: `+${clonedItems.length}`,
        color: "#E6E6E6",
        small: true,
      },
    ];
  } else {
    return items.map((value) => {
      return {
        ...value,
        initial: initial(value.name),
      };
    });
  }
};

export default {
  props: {
    spec: { type: Object, required: true },
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    $ready() {
      this.items = getList(this.spec.items);
    },
    handleClick() {
      if (!this.spec.dialogUrl) {
        return null;
      }
      GLib.action.execute(
        { action: "dialogs/open-v1", url: this.spec.dialogUrl },
        this
      );
    },
  },
};
</script>

<style></style>